.select-container {
  display: block;
  font-size: 16px;
  position: relative;
}

.select-container + .select-container {
  margin-top: 1em;
}

.select-container .select-box {
  display: flex;
  justify-content: space-between;
  max-height: 38px;
  border: 1px solid #dfe3e8;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 8px 10px;
  cursor: pointer;
}

.select-container .select-box .placeholder {
  margin: 0;
  display: flex;
  align-items: center;
  border: none;
  font-size: 16px;
}

.select-container .select-box .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container .select-menu {
  flex-direction: column;
  align-items: stretch;
  max-height: 296px;
  padding: 10px 2px;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba(45, 55, 72, 0.1);
  border-radius: 2px;
  transition: 300ms ease;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.select-container .select-menu .menu-item {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown .placeholder {
  font-weight: 300;
  font-size: 14px;
}

.dropdown .placeholder {
  color: #757e90;
}

.dropdown .icon {
  color: #637381;
}

.select-menu__radio-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 8px;
  margin: 8px 0px;
  align-items: center;
  z-index: 2;
}

.dropdown__radio {
  cursor: pointer;
}

.user-input {
  max-height: 38px;
  border: 1px solid #dfe3e8;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 8px 10px;
  outline: none;
  min-width: 240px;
  width: 100%;
}

.select-container + .user-input {
  margin-top: 1em;
}

.user-input:focus {
  border-color: rgb(151, 78, 188);
}
@media screen and (min-width: 769px) {
  .select-container + .user-input {
    margin-top: 0;
    margin-left: 1em;
  }
  .user-input {
    width: 300px;
  }
}
